<template>
  <dash-page-new
      :root="$t('Dashboard')"
      :root-location="businessDashLink('')"
      :title="$t('Clients')"
      :subtitle="$t('All')"
      icon="mdi-account-multiple"
      :filters="filterItems"
      @filter="filter = $event"
      filters-input=""
      no-side >

    <template #header_action>
      <div class="d-flex align-center">

        <v-btn  @click="exportData"  min-width="50" outlined height="40" class="mr-3" :color="wsDARKLIGHT" >
          <v-icon>mdi-download</v-icon>
        </v-btn>

        <ft-select
            :items="headerActionsSelect"
        >
          <ws-button
              :click-stop="false"
              left-icon="mdi-plus"
              label="AddClient"
          />
        </ft-select>
      </div>


    </template>


    <template #default>
      <!--  Clients-->
      <v-sheet class="wsRoundedHalf py-6">
        <ws-data-table
            :items="itemsFiltered"
            :headers="headers"
            :search="filter.search"
            @current-items="getExportData"
        >
          <template v-slot:item="{item}">
            <v-hover #default="{hover}">
              <tr class="pointer" @click="$router.push(businessDashLink('subscribers/' + item.uuid  + '/timeline' ))"  @contextmenu="openMenu($event, item)">
                <td>
                  <div class="d-flex align-center text-no-wrap">
                    <v-avatar class="mr-3"  :color="wsACCENT"
                              size="40" key="profile_picture" >
                      <v-img v-if="item.img" :src="item.img" transition="xxx">
                        <template slot="placeholder">
                          <v-img src="@/assets/placeholder/user_blank.jpg" />
                        </template>
                      </v-img>
                      <v-icon  dark v-else>mdi-account-tie</v-icon>
                    </v-avatar>
                    <div>
                      <h5> {{ item.name }}  </h5>
                      <h6 class="font-weight-light"> {{ item.email}}</h6>
                    </div>
                  </div>
                </td>
                <td width="10px">
                  <ws-chip icon="mdi-phone"  :text="item.phone"  :color="wsACCENT" />
                </td>
                <td  width="10px" class="text-no-wrap">
                  <ws-chip icon="mdi-calendar" :color="wsACCENT" :text="PARSE_TIME_STAMP(item.registered , true)"></ws-chip>
                </td>
                <td width="10px" class="text-no-wrap">
                  <ws-chip icon="mdi-calendar" :color="wsACCENT" :text="PARSE_TIME_STAMP(item.last_access , true)"></ws-chip>
                </td>
                <td width="10px">
                  <ws-chip icon="mdi-school" :text="item.courses_count" bold :color="wsACCENT" />
                </td>
                <td width="10px">
                  <ws-chip icon="mdi-cart" :text="item.orders_count" bold :color="wsACCENT" />
                </td>
                <td width="10px" class="text-no-wrap">
                  <ws-chip icon="mdi-cash" :text="item.total_income + ' ' + BUSINESS_CURRENCY" bold :color="wsACCENT" />
                </td>
                <td width="200px" class="text-no-wrap">
                  <contact-tags-table-item
                      @add-tag="tagsSelect.push($event)"
                      :item="item"
                      :contact-id="item.contact_id"
                      :tags-select="tagsSelect"
                      :hover="hover"
                  />
                </td>
                <td width="10px" class="text-no-wrap">
                  <h5>{{ item.country }}</h5>
                </td>
                <td width="10px" class="text-no-wrap">
                  <h5>{{ item.city }}</h5>
                </td>

                <template v-for="(field , i) in customFields" >
                  <td :key="i + item.uuid">
                    <h5 class="text-center">{{ item['custom_field_' + field.id] }}</h5>
                  </td>
                </template>


                <td width="10px">
                  <ft-select @input="editAction($event, item)" :items="editActionTypes(selectedUser)" >
                    <v-btn :color="wsATTENTION" icon>
                      <v-icon>mdi-pencil-circle</v-icon>
                    </v-btn>
                  </ft-select>
                </td>


              </tr>
            </v-hover>
            </template>
        </ws-data-table>
      </v-sheet>
    </template>

    <template #dialog>

      <!-- Display Add User dialog window -->
      <ws-user-edit-dialog
          :display="displayAddClientDialog"
          @save="addNewClient"
          @close="displayAddClientDialog = false"
          v-model="entityData"
      />

      <!-- Display Import Users from file -->
      <ws-file-import-dialog
          v-if="displayImportClientDialog"
          @success="displayImportClientDialog = false; initPage()"
          v-model="displayImportClientDialog"
          entity="users"
      />

      <!-- Display New Tag Dialog -->
      <wsDialog
          v-model="displayNewTagDialog"
          :title="$t('AddTag')"
          @save="addNewTag">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
            <v-text-field
                v-model="entityData.name"
                :placeholder="$t('Name')"
                dense outlined />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <ws-color-picker
                v-model="entityData.color"
                :placeholder="$t('Color')"
            ></ws-color-picker>
          </v-col>
        </v-row>
      </wsDialog>

      <!-- Display Delete Student dialog window -->
      <ws-dialog
          v-model="displayDeleteUser"
          @save="deleteUser"
          :save-text="$t('Delete')"
          :title="$t('DeleteUser')"
      >
        <h5 class="mb-5 text-center"> {{ $t('ConfirmClientDeleteMessage') }}</h5>
        <h5 class="mb-5 text-center"> {{ $t('ConfirmClientDeleteInstruction') }}</h5>

        <h4 class="mb-5 text-center"> {{ selectedUser.email }}</h4>
        <ws-text-field
            v-model="deleteUserData"
        />

      </ws-dialog>

      <ft-select
          @input="editAction($event, selectedUser)"
          :items="editActionTypes(selectedUser)"
          :expanded="displayContextMenu"
          :x="x"
          :y="y"
          absolute
      />



    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import languagesList from "@/mixins/languagesList";
import spectatorMode from "@/mixins/spectatorMode";
import wsUserEditDialog from "@/components/UI/userEditDialog/wsUserEditDialog.vue";
import wsFileImportDialog from "@/components/UI/wsFileImportDialog.vue";
import contactTagsTableItem from "@modules/contacts/components/UI/contactTagsTableItem.vue";

export default {
  name: "BusinessClients",
  mixins : [languagesList,spectatorMode],
  components : {
    contactTagsTableItem,
    wsUserEditDialog,
    wsFileImportDialog
  },
  data(){
    return {

      displayDeleteUser : false,
      deleteUserData : null,

      customFields : [],
      referralsSelect : [],
      customFieldsFilters : [],

      displayAddClientDialog : false,
      displayImportClientDialog : false,

      passwordError : false,
      emailError : false,
      phoneError : false,
      nameError : false,
      userError : false,

      // import variables
      fromStudentsStep : 0,
      importResult : {},
      importComplete : false,
      reviewErrors : false,
      fieldsErrors : {},


      displayNewTagDialog : false,
      entityData : {},
      tagsSelect : [],
      filterTags : [],
      search : '',
      filter : {},
      items: [],
      displayContextMenu : false,
      x : 0,
      y : 0,
      selectedUser : {},
    }
  },
  computed : {
    headerActionsSelect() {
      return [
        { text : this.$t('AddNewClient') , 
          icon : 'mdi-account-plus',
          action : this.displayAddClient
        },
        { text : this.$t('ImportFromFile') ,
          icon : 'mdi-table-arrow-left',
          action : this.displayImport
        },
      ]
    },
    headers() {
      let items = [
        { text : this.$t('HumanName')      , value : 'name'          },
        { text : this.$t('Phone')          , value : 'phone'         },
        { text : this.$t('DateRegistered') , value : 'registered'    },
        { text : this.$t('LastActivity')   , value : 'last_access'   },

        { text : this.$t('Courses')        , value : 'courses_count' },
        { text : this.$t('Orders')         , value : 'orders_count'  },
        { text : this.$t('Income')         , value : 'total_income'  },
        { text : this.$t('Tags')           , value : 'tags'          },
        { text : this.$t('Country')        , value : 'country'       },
        { text : this.$t('City')           , value : 'city'          },
        { text : this.$t('Email')          , value : 'email' , align: 'd-none'},
      ]

      this.customFields.forEach(field => {
        items.push( { text : field.name , value : "custom_field_" + field.id })
      })

      items.push( { value : 'action', sortable : false } )

      return items
    },
    itemsFiltered() {
      let items = this.items

      if ( Array.isArray(this.filter.tags) ) {
        if ( this.filter.tags.length > 0 ) {
          items = items.filter( el=>
              el.tags.map(tag => tag.uuid).filter(tag_uuid => this.filter.tags.includes(tag_uuid)).length === this.filter.tags.length
          )
        }
      }

      if ( Array.isArray(this.filter.country) ) {
        if ( this.filter.country.length > 0 ) {
          items = items.filter( el=> this.filter.country.includes(el.country) )
        }
      }

      if ( this.filter.referral ) {
        if ( this.filter.referral === '$no_referral') {
          items = items.filter( el=> !el.referral )
        } else {
          items = items.filter( el=> el.referral ===  this.filter.referral )
        }


      }

      this.customFieldsFilters.forEach(field => {

        if ( field.type === 'select_multiple' && this.filter[field.value] ) {

          if ( Array.isArray( this.filter[ field.value])  ) {
            if (this.filter[field.value].length > 0 ) {
              items = items.filter( el=> this.filter[field.value].includes(el[field.value]) )
            }
          }
        }
      })

      return items
    },
    tagsSelectFiltered() {

      let items = this.tagsSelect

      items.forEach(tag => {
        if ( this.filterTags.includes(tag.value)) {
          tag.icon = 'mdi-minus'
        } else {
          tag.icon = 'mdi-plus'
        }
      })

      return items

    },
    filterItems() {
      let items = [
          { text : this.$t('Tags') , value : 'tags' , type : 'select_multiple' , items : this.tagsSelect },
          { text : this.$t('Country') , value : 'country' , type : 'select_multiple' , items : this.COUNTRIES_SELECT }
      ]
      if ( this.referralsSelect.length > 0 ) {
        items.push({ text : this.$t('Referrals') , value : 'referral' , type : 'select' , items : this.referralsSelect })
      }

      items = [...items , ...this.customFieldsFilters ]

      return items

    },
    importCancelText() {
      if (this.importComplete ) { return this.reviewErrors ? this.$t('Back') : this.$t('Finish') }
      if (this.reviewErrors) { return this.$t('Back') }
      return this.$t('Back')
    },
  },
  watch : {
    'EVENTS_TRIGGER.BUSINESS_USER'() {
      this.initPage()
    },
  },
  methods : {
    ...mapActions( 'crmSystem', [
        'GET_BUSINESS_CLIENTS' ,
        'ADD_NEW_TAG',
        'ADD_CLIENT_PROFILE',
        'DELETE_CLIENT_PROFILE',
        'EDIT_CLIENT_TAGS'
    ]),
    ...mapActions('import',['UPLOAD_IMPORT_USERS']),

    ...mapActions('export',[
      'EXPORT_TABLE_DATA'
    ]),
    getExportData($event) {
      this.itemsForExport = $event
    },

    async exportData() {
      let data = {
        data : this.itemsForExport,
        headers : this.headers
      }
      let blob = await this.EXPORT_TABLE_DATA(data)
      if ( !blob ) {
        return
      }
      var url  = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'import_data.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();

      this.notify(this.$t('TableExported'))

    },

    openDeleteUser(item) {
      this.deleteUserData = null
      this.displayDeleteUser = true
      this.selectedUser = item
    },
    async deleteUser() {
      if ( this.selectedUser.email !== this.deleteUserData ) {
        return this.notify(this.$t('WrongUserEmail'),'warning')
      }
      let result = await this.DELETE_CLIENT_PROFILE(this.selectedUser.uuid)
      if ( !result ) {
        return this.notify(this.$t('Error'),'warning')
      }


      let index = this.items.findIndex( el=> el.uuid === this.selectedUser.uuid )
      if ( index === -1  ) {
        return this.notify(this.$t('Error'),'warning')
      }
      this.index = this.items.splice(index, 1)

      this.notify(this.$t('UserDeleted'))
      this.displayDeleteUser = false
    },

    displayAddClient() {
      this.entityData = {}
      this.newEntity = true
      this.displayAddClientDialog = true
    },
    async addNewClient() {
      
      let result = await this.ADD_CLIENT_PROFILE(this.entityData)
      if ( !result ) {

        if ( this.$store.state.ajax.error === 'Email already exists' ) {
          this.userError = true
          this.userError = true
          this.notify(this.$t('UserAlreadyExists') , 'warning')
        } else {
          this.notify(this.$t('Error'))
        }

        return
      }
      this.items.unshift(result)
      this.notify(this.$t('ClientProfileCreated'))
      this.displayAddClientDialog = false
    },
    
    async uploadUsers(file) {
      this.importComplete     = false
      this.importResult = {
        import_errors : [],
        failed : [],
        new_users : []
      }
      let data = {
        fileFormData : file
      }
      let result = await this.UPLOAD_IMPORT_USERS(data)
      if ( !result ) { return false }
      this.notify(this.$t('ImportFinished'))
      this.initPage()
      this.importComplete = true
      this.importResult = result
    },
    displayImport() {
      this.newEntity = true
      this.displayDialog = false
      this.displayImportClientDialog = true
    },
    importCancelButton() {

      if (this.reviewErrors) {
        this.reviewErrors = false
        return
      }

      this.displayImportClientDialog = false
      this.displayNewClientDialog = true

      if (this.importComplete) {
        this.importComplete = false
        this.reviewErrors = false
        this.importResult = false
      }
    },
    
    editAction(type,item) {
      this.selectedUser = item
      switch(type) {
        case 'add_note'         : return this.displayAddEvent(item, 'note');
        case 'add_call'         : return this.displayAddEvent(item, 'call');
        case 'add_video_call'   : return this.displayAddEvent(item, 'video_call');
        case 'add_task'         : return this.displayAddEvent(item, 'task');
        case 'archive_business' : return this.displayArchiveBusiness();
        case 'ADD_NEW_TAG'      : return this.displayAddNewTag();
        case 'login_as_user'    : return this.loginAsUser(this.selectedUser.uuid , this.businessDashLink('subscribers') );
        case 'delete' : return this.openDeleteUser(item)
      }

      if (type.includes('add_tag_') ) {
        this.changeTag(type.replace('add_tag_',''),'add')
      }
      if (type.includes('remove_tag_')) {
        this.changeTag(type.replace('remove_tag_',''),'remove')
      }

    },
    editActionTypes(item) {
      return [
        { text: this.$t('ViewClientCard')  , icon : 'mdi-eye'   ,  route : this.businessDashLink('subscribers/' + item.uuid) + '/timeline' },
        { text: this.$t('LogInAsUser')     , value : 'login_as_user' ,  icon : 'mdi-login'   },

        { text: this.$t('Tags'),  icon : 'mdi-tag', children : this.getBusinessFreeTagsSelect(item.tags) },
        { text: this.$t('Delete') , icon : 'mdi-delete' , value : 'delete'}
        // { text: this.$t('Add') ,  icon : 'mdi-plus' , children : [
        //     { text: this.$t('Note')      , icon : 'mdi-plus'  ,  value : 'add_note' },
        //     { text: this.$t('Call')      , icon : 'mdi-plus'  ,  value : 'add_call' },
        //     { text: this.$t('VideoCall') , icon : 'mdi-plus'  ,  value : 'add_video_call' },
        //     { text: this.$t('Task')      , icon : 'mdi-plus'  ,  value : 'add_task' },
        //     { text: this.$t('Bug')      , icon : 'mdi-plus'  ,  value : 'add_bug' },
        //   ] },
        // { text: 'Приховати' , icon : 'mdi-close' ,  value : 'archive_business' }
      ]
    },

    async addNewTag() {

      if (!this.entityData.name) {
        return this.notify(this.$t('PleaseEnterName'))
      }

      let result = await this.ADD_NEW_TAG(this.entityData)
      if ( !result || result === true ) {
        return
      }
      this.tagsSelect.push(result)
      this.changeTag(result.value,'add')
      this.displayNewTagDialog = false
    },
    async changeTag(tag,type) {
      let tags = this.selectedUser.tags
      let tagsArray = tags.map(el => el.uuid)
      if ( type === 'add' ) {
        tagsArray.push(tag)
      }
      if ( type === 'remove' ) {
        let index = tagsArray.findIndex( el => el === tag )
        if ( index === -1 ) { return }
        tagsArray.splice(index,1)
      }

      let data = {
        user_id : this.selectedUser.uuid,
        tags : tagsArray
      }

      let result = await this.EDIT_CLIENT_TAGS(data);
      if ( !result ) { return }
      this.selectedUser.tags = result !== true ?  result : []

    },

    getTagName(tag) {
      let tagEntity = this.tagsSelect.find(el => el.value === tag)
      if ( tagEntity ) { return tagEntity.text }
      return 'tag'
    },
    getBusinessFreeTagsSelect(tags = []) {

      let items = JSON.parse(JSON.stringify(this.tagsSelect) )

      if (!tags ) { return [] }

      let  tagsArray = tags.length > 0 ?  tags.map( el => el.uuid ) : []

      items.forEach((item) => {
        if ( tagsArray.includes(item.value) ) {
          item.value = 'remove_tag_' + item.value
          item.icon = 'mdi-minus'
        } else {
          item.value = 'add_tag_'    + item.value
          item.icon = 'mdi-plus'
        }
      })

      items.push({
        text : this.$t('AddTag') ,
        value : 'ADD_NEW_TAG'
      })


      return items
    },
    changeTagFilter(tag) {
      if ( this.filterTags.includes(tag)) {
        let index = this.filterTags.findIndex(el => el === tag)
        if ( index === -1 ) { return }
        this.filterTags.splice(index,1)
      } else {
        this.filterTags.push(tag)
      }
    },

    displayAddNewTag() {
      this.entityData = {}
      this.displayNewTagDialog = true
    },
    openMenu($event , item) {
      this.selectedUser = item
      $event.preventDefault()
      this.displayContextMenu = false
      this.x = $event.clientX
      this.y = $event.clientY
      this.$nextTick(() => {
        this.displayContextMenu = true
      })
    },

    async initPage() {
      let result = await this.GET_BUSINESS_CLIENTS()
      if ( !result || result === true ) { return }
      this.items = result.clients
      this.tagsSelect = result.tags
      this.customFields = result.custom_fields
      this.customFieldsFilters = result.custom_fields_filters
      this.referralsSelect = result.referrals || []
      if ( this.referralsSelect.length > 0 )  {
        this.referralsSelect.unshift({ text : this.$t('referral.none') , value : '$no_referral' })
      }
      this.READ_EVENTS('business_user')
    }
  },
  mounted() {
   this.initPage()
  }

}
</script>

<style scoped>

</style>